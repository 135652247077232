import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	fireWithDebounce(e) {
		window.clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			this.element.requestSubmit();
		}, 170);
	}

	fireImmediately(e) {
		this.element.requestSubmit();
	}
}
